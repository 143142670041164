import React from 'react'
import gql from 'graphql-tag'
import DataGridCell from '@unowmooc/react-ui-kit/lib/DataGrid/Cell'
import { FormattedMessage } from 'react-intl'
import I18nProvider from '@unowmooc/i18n'
import moment from 'moment/moment'
import { Link, withRouter } from 'react-router-dom'
import DataGridGraphQL from 'components/DataGridGraphQL'
import { withUser } from 'store/UserProvider'
import PropTypes from 'prop-types'

const fragment = gql`
  fragment ListVirtualClassroomFragment on VirtualClassroom {
    id
    startDate
    endDate
    sequenceNumber
    session {
      id
      identifier
      course {
        id
        title
      }
    }
  }
`
const ListCreatedVirtualClassrooms = ({ location, user, type }) => {
  const filters = {
    animators_id: user.id,
  }

  if (type === 'planned') {
    filters.startDate = { gte: moment().add(1, 'days').format('YYYY-MM-DD 00:00') }
  }

  if (type === 'today') {
    filters.startDate = {
      gte: moment().format('YYYY-MM-DD 00:00'),
      leq: moment().format('YYYY-MM-DD 23:59'),
    }
  }

  return (
    <>
      <DataGridGraphQL
        queryName="virtualClassrooms"
        queryFragment={fragment}
        location={location}
        typeName="VirtualClassroom"
        sort={['startDate DESC', 'id DESC']}
        filters={filters}
        limit={10}
      >
        <DataGridCell
          label={<FormattedMessage id="vc_expert_list.list.columns.vc_label" />}
          render={item => (
            <>
              <Link to={I18nProvider.getLinkRoute('/expert/virtual-classrooms/view/{id}', { id: item.id })}>
                {item.session.identifier} #{item.sequenceNumber}
              </Link>
            </>
          )}
        />
        <DataGridCell
          label={<FormattedMessage id="vc_expert_list.list.columns.date" />}
          property="startDate"
          render={resultCell => moment(resultCell).format('DD/MM/YYYY')}
        />
        <DataGridCell
          label={<FormattedMessage id="vc_expert_list.list.columns.hour_start" />}
          property="startDate"
          render={resultCell => moment(resultCell).format('hh:mm')}
        />
        <DataGridCell
          label={<FormattedMessage id="vc_expert_list.list.columns.hour_end" />}
          property="endDate"
          render={resultCell => moment(resultCell).format('hh:mm')}
        />
        <DataGridCell
          label={<FormattedMessage id="vc_expert_list.list.columns.course_title" />}
          property="session.course.title"
        />
      </DataGridGraphQL>
    </>
  )
}

ListCreatedVirtualClassrooms.propTypes = {
  location: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  type: PropTypes.string.isRequired,
}

export default withUser(withRouter(ListCreatedVirtualClassrooms))
