import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import I18nProvider from '@unowmooc/i18n'
import EditPage from './edit'
import ViewVirtualClassroomPage from './view'
import ListRoute from './list_route'

const VirtualClassroomsPage = ({ match: { url } }) => (
  <Switch>
    <Route exact path={url} render={() => <Redirect to={`${url}/list/${I18nProvider.translatePath('to-plan')}`} />} />
    <Route path={`${url}/list`} component={ListRoute} />
    <Route
      path={`${url}/${I18nProvider.translatePath('view')}/:idVirtualClassroom`}
      component={ViewVirtualClassroomPage}
    />
    <Route path={`${url}/:idSession`} component={EditPage} />
  </Switch>
)

VirtualClassroomsPage.propTypes = {
  match: PropTypes.shape().isRequired,
}

export default VirtualClassroomsPage
