import React from 'react'
import gql from 'graphql-tag'
import PageQuery from 'components/PageQuery'
import { Box, Tag } from '@unowmooc/react-ui-kit'
import { FormattedMessage } from 'react-intl'
import I18nProvider from '@unowmooc/i18n'
import Label from '@unowmooc/react-ui-kit/lib/FieldLayout/Label'
import styled from '@emotion/styled'
import { Col, Row } from 'react-grid-system'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import { Link } from 'react-router-dom'
import { Text } from '@unowmooc/text'
import moment from 'moment'
import PropTypes from 'prop-types'
import LinkButtonIcon from '../../../../../components/LinkButtonIcon'
import FormattedDateInterval from '../../../../../components/FormattedDateInterval'
import InputCopy from '../../../../../components/InputCopy'
import VirtualClassroomsPage from '../../sessions/view/virtualClassrooms'
import config from 'config'

const InfoSequence = ({ info, titleKey }) => (
  <>
    {info && (
      <div style={{ marginBottom: '15px' }}>
        <h4 style={{ marginBottom: '8px' }}>
          <FormattedMessage id={titleKey} />
        </h4>
        <div style={{ lineHeight: '28px' }} dangerouslySetInnerHTML={{ __html: info }} />
      </div>
    )}
  </>
)

InfoSequence.propTypes = {
  info: PropTypes.string.isRequired,
  titleKey: PropTypes.string.isRequired,
}

const StyledCol = styled(Col)`
  ${mq.md(css`
    &:nth-of-type(1),
    &:nth-of-type(2) {
      margin-bottom: 20px;
    }
  `)};

  ${mq.sm(css`
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  `)};
`

const ViewVirtualClassroomPage = ({
  match: {
    params: { idVirtualClassroom },
  },
}) => (
  <PageQuery
    query={gql`
      query VirtualClassroomView($vcId: ID!) {
        virtualClassroom(id: $vcId) {
          id
          sequenceNumber
          startDate
          endDate
          isOnSite
          addressLine1
          addressLine2
          postCode
          city
          country
          session {
            id
            startAt
            endAt
            identifier
            webexLicence
            virtualClassroomsBySequence {
              sequenceNumber
              title
              goal
              why
              requirements
            }
            course {
              id
              slug
              title
            }
          }
        }
      }
    `}
    variables={{ vcId: idVirtualClassroom }}
    render={({ virtualClassroom }) => {
      let currentSequence = null
      virtualClassroom.session.virtualClassroomsBySequence.forEach(function (virtualClassroomBySequence) {
        if (virtualClassroomBySequence.sequenceNumber === virtualClassroom.sequenceNumber) {
          currentSequence = virtualClassroomBySequence
        }
      })

      // eslint-disable-next-line compat/compat
      const copyVirtualClassroomLink = `${window.location.origin}${I18nProvider.getLinkRoute(
        `/courses/{slugCourse}/{sessionId}/virtual-classrooms`,
        {
          slugCourse: virtualClassroom.session.course.slug,
          sessionId: virtualClassroom.session.id,
        },
      )}`

      let mapsUrl = 'https://www.google.fr/maps/place/'
      if (virtualClassroom.isOnSite) {
        mapsUrl += `${virtualClassroom.addressLine1} ${virtualClassroom.postCode} ${virtualClassroom.city} ${virtualClassroom.country}`
      }

      return (
        <>
          <h2 style={{ marginBottom: '30px' }}>
            <FormattedMessage id={virtualClassroom.isOnSite ? 'virtual_classroom.label_cms_block_is_on_site'
              : 'virtual_classroom.label_cms_block'} values={{number: virtualClassroom.sequenceNumber}} /> du{' '}
            {moment(virtualClassroom.startDate).format('DD/MM/YYYY')} de{' '}
            {moment(virtualClassroom.startDate).format('hh:mm')} à {moment(virtualClassroom.endDate).format('hh:mm')}
          </h2>

          <h3 style={{ marginBottom: '15px' }}><FormattedMessage id="vc_view.session" /></h3>

          <Row style={{ marginBottom: '30px' }}>
            <StyledCol xs={12} sm={6} md={6}>
              <Box>
                <Tag small mr="15px" data-cy="session-identifier">
                  {virtualClassroom.session.identifier}
                </Tag>
                <br />
                <Text color="manatee">
                  <>
                    {virtualClassroom.session.course.title}&nbsp;-&nbsp;
                    <FormattedDateInterval
                      id="commons.session_from_to"
                      from={virtualClassroom.session.startAt}
                      to={virtualClassroom.session.endAt}
                    />
                  </>
                </Text>
              </Box>
            </StyledCol>
            <StyledCol xs={12} sm={6} md={6}>
              <Box>
                <div style={{ textAlign: 'center' }}>
                  <LinkButtonIcon
                    tag={Link}
                    style={{ marginRight: '15px' }}
                    to={I18nProvider.getLinkRoute('/expert/sessions/{idSession}', {
                      idSession: virtualClassroom.session.id,
                    })}
                    theme="secondary"
                    size="small"
                    icon="right"
                  >
                    <FormattedMessage id="sessions.session.see_link" />
                  </LinkButtonIcon>
                  <LinkButtonIcon
                    tag={Link}
                    to={I18nProvider.getLinkRoute('/courses/{slugCourse}/{idSession}', {
                      slugCourse: virtualClassroom.session.course.slug,
                      idSession: virtualClassroom.session.id,
                    })}
                    icon="right"
                    size="small"
                  >
                    <FormattedMessage id="sessions.session.go_to_link" />
                  </LinkButtonIcon>
                </div>
              </Box>
            </StyledCol>
          </Row>

          {!virtualClassroom.isOnSite && virtualClassroom.session.webexLicence && (
            <div style={{ marginBottom: '30px' }}>
              <h3 style={{ marginBottom: '15px' }}><FormattedMessage id="vc_view.connexion_infos" /></h3>

              <Box>
                <div style={{ marginBottom: '15px' }}>
                  <FormattedMessage id="vc_view.connexion_infos_message" />
                </div>

                <InputCopy id="expert-virtual-classroom-link" value={virtualClassroom.session.webexLicence} />
              </Box>
            </div>
          )}

          {virtualClassroom.isOnSite && (
            <div style={{ marginBottom: '30px' }}>
              <h3 style={{ marginBottom: '15px' }}>Lieu</h3>

              <Box>
                {virtualClassroom.addressLine1}
                <br />
                {virtualClassroom.addressLine2 && (
                  <span>
                    {virtualClassroom.addressLine2}
                    <br />
                  </span>
                )}
                {virtualClassroom.postCode} {virtualClassroom.city}
                <br />
                {virtualClassroom.country}
                <br />
                <br />
                <a href={mapsUrl} target="unow-vc-map-link">
                  <FormattedMessage id="vc_view.view_on_google_maps" />
                </a>
              </Box>
            </div>
          )}

          {virtualClassroom.isOnSite && (
            <div style={{ marginBottom: '30px' }}>
              <h3 style={{ marginBottom: '15px' }}><FormattedMessage id="vc_view.physical_registration" /></h3>
              <Row style={{ marginBottom: '30px' }}>
                <StyledCol xs={12} sm={8} md={8}>
                  <Box>
                    <div style={{minHeight: "40px"}}>
                      <FormattedMessage id="vc_view.physical_registration_message" />
                    </div>
                  </Box>
                </StyledCol>
                <StyledCol xs={12} sm={4} md={4}>
                  <Box>
                    <div style={{ textAlign: 'center' }}>
                      <LinkButtonIcon
                        tag="a"
                        href={config.env.GRAPHQL_API_URL + `/virtualclassroom/${virtualClassroom.id}/attendance-sheet-pdf`}
                        target="attestation-pdf"
                        icon="download"
                        size="small"
                        theme="secondary"
                      >
                        <FormattedMessage id="commons.download" />
                      </LinkButtonIcon>
                    </div>
                  </Box>
                </StyledCol>
              </Row>
            </div>
          )}

          {currentSequence && (
            <div style={{ marginBottom: '30px' }}>
              <h3 style={{ marginBottom: '15px' }}><FormattedMessage id="vc_view.about" /></h3>

              <Box>
                <InfoSequence info={currentSequence.title} titleKey="vc_view.title" />
                <InfoSequence info={currentSequence.goal} titleKey="virtual_classroom.goal_title" />
                <InfoSequence info={currentSequence.why} titleKey="virtual_classroom.why_title" />
                <InfoSequence info={currentSequence.requirements} titleKey="virtual_classroom.requirements_title" />
              </Box>
            </div>
          )}

          <h3 style={{ marginBottom: '15px' }}><FormattedMessage id="vc_view.registration" /></h3>
          <VirtualClassroomsPage
            sessionId={virtualClassroom.session.id}
            location={{ search: `vc=${virtualClassroom.id}` }}
            showSelector={false}
            showVirtualClassroomLink={false}
            showLinkToVirtualClassroom={false}
            summaryMessage={<FormattedMessage id="vc_view.registration_message" />}
          />

          <div style={{ marginBottom: '30px' }} />

          <h3 style={{ marginBottom: '15px' }}><FormattedMessage id="vc_view.participant_virtual_classroom_page" /></h3>
          <Box>
            <div style={{ marginBottom: '15px' }}>
            <FormattedMessage id="vc_view.participant_virtual_classroom_message" />
            </div>
            <Label label={<FormattedMessage id="vc_view.participant_virtual_classroom_page_label" />} />
            <InputCopy value={copyVirtualClassroomLink} />
          </Box>
        </>
      )
    }}
  />
)

ViewVirtualClassroomPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      idVirtualClassroom: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
}

export default ViewVirtualClassroomPage
