import React from 'react'
import gql from 'graphql-tag'
import { Flex } from 'reflexbox'
import { Alert, AwesomeUnowLoader, DataGrid } from '@unowmooc/react-ui-kit'
import DataGridCell from '@unowmooc/react-ui-kit/lib/DataGrid/Cell'
import { FormattedMessage } from 'react-intl'
import I18nProvider from '@unowmooc/i18n'
import { useQuery } from 'react-apollo'
import moment from 'moment/moment'
import { Link } from 'react-router-dom'

const QUERY_VIRTUAL_CLASSROOMS = gql`
  query sessionsWhereToCreateVirtualclassrooms {
    sessionsForAuthenticatedAnimatorWithVirtuaclassroomsInformationsMissing {
      id
      identifier
      startAt
      endAt
      course {
        id
        title
      }
    }
  }
`

const ListPage = () => {
  const { data, loading } = useQuery(QUERY_VIRTUAL_CLASSROOMS, { fetchPolicy: 'no-cache' })

  if (loading) {
    return (
      <Flex justifyContent="center">
        <AwesomeUnowLoader />
      </Flex>
    )
  }

  return (
    <>
      <Alert style={{ marginTop: '30px', marginBottom: '30px' }}>
        <FormattedMessage id="vc_input.list.alert" />
      </Alert>
      <DataGrid
        data={data.sessionsForAuthenticatedAnimatorWithVirtuaclassroomsInformationsMissing}
        emptyStateMessage={<FormattedMessage id="vc_input.list.empty_message" />}
      >
        <DataGridCell
          label={<FormattedMessage id="vc_input.list.columns.session" />}
          render={session => (
            <>
              <Link to={I18nProvider.getLinkRoute('/expert/virtual-classrooms/{id}', { id: session.id })}>
                {session.identifier}
              </Link>
            </>
          )}
        />
        <DataGridCell
          label={<FormattedMessage id="vc_input.list.columns.start_date" />}
          property="startAt"
          render={resultCell => moment(resultCell).format('DD/MM/YYYY')}
        />
        <DataGridCell
          label={<FormattedMessage id="vc_input.list.columns.end_date" />}
          property="endAt"
          render={resultCell => moment(resultCell).format('DD/MM/YYYY')}
        />
        <DataGridCell label={<FormattedMessage id="vc_input.list.columns.course_title" />} property="course.title" />
      </DataGrid>
    </>
  )
}

export default ListPage
