import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import I18nProvider from '@unowmooc/i18n'
import { FormattedMessage } from 'react-intl'
import RouteTabs from 'components/RouteTabs'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '@unowmooc/themes'
import ListAllPage from '../list_created/all'
import ListTodayPage from '../list_created/today'
import ListPlannedPage from '../list_created/planned'
import ListPage from '../list'

const StyledTabs = styled(RouteTabs)`
  margin-bottom: 45px;
  margin-top: 15px;

  ${mq.sm(css`
    margin: 0 -15px 20px -15px;
  `)};
`

const ListRoute = ({ match: { url }, location }) => {
  const statuses = ['to-plan', 'today', 'planned', 'all']

  return (
    <>
      <h2>
        <FormattedMessage id="vc_input.common.vcs" />
      </h2>

      <StyledTabs pathname={location.pathname} messageBaseKey="vc_expert_list.tabs" items={statuses} />

      <Switch>
        <Route path={`${url}/${I18nProvider.translatePath('to-plan')}`} component={ListPage} />
        <Route path={`${url}/${I18nProvider.translatePath('today')}`} component={ListTodayPage} />
        <Route path={`${url}/${I18nProvider.translatePath('planned')}`} component={ListPlannedPage} />
        <Route path={`${url}/${I18nProvider.translatePath('all')}`} component={ListAllPage} />
      </Switch>
    </>
  )
}

ListRoute.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default ListRoute
